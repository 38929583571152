import Vue from "vue";

Vue.mixin({
  methods: {
    // ログ⇒CSVに変換してダウンロード
    csvDownload({ params }) {
      if (params.json_log.length == 0) return;

      // メモ：出力するカラムがJSONの時にカラムを増やす場合は修正が必要
      // 現状はJSON全体を文字列に変換して出力
      let csv = "\ufeff";
      let isFirst = true;
      params.element.map(e => {
        csv += isFirst ? e : "," + e;
        isFirst = false;
      });
      csv += "\n";

      params.json_log.map(e => {
        isFirst = true;
        let line = "";
        params.element.map(i => {
          if (e[i] instanceof Object) {
            line += isFirst ? JSON.stringify(e[i]) : "," + JSON.stringify(e[i]);
          } else {
            line += isFirst ? e[i] : "," + e[i];
          }
          isFirst = false;
        });
        csv += line + "\n";
      });
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = params.out_name + ".csv";
      link.click();
    },
    txtDownload({ params }) {
      let blob = new Blob([params.txt], { type: "text/plain" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = params.out_name + ".txt";
      link.click();
    }
  }
});
