import Vue from "vue";

/*GPS端末系インスタンス用共通関数 */

Vue.prototype.$form_device_infos_def = () => {
  return {
    car_device_id: "",
    password: "",
    ipaddr: "",
    macaddr: "",
    car_type: "",
    car_data_id: ""
  };
};
Vue.prototype.$form_device_err_infos_def = () => {
  return {
    car_device_id: "",
    password: "",
    ipaddr: "",
    macaddr: "",
    car_type: "",
    car_data_id: ""
  };
};

Vue.prototype.$device_search_items = [
  { search_colum: "car_device_id", text: "GPS端末ID", value: "0" },
  { search_colum: "ipaddr", text: "IPアドレス", value: "1" },
  { search_colum: "macaddr", text: "MACアドレス", value: "2" },
  { search_colum: "car_data_id", text: "車両データID（IMEI）", value: "3" },
  { search_colum: "car_type", text: "車種", value: "4" }
];

Vue.prototype.$car_add_device_search_items = [
  { search_colum: "car_device_id", text: "GPS端末ID", value: "0" },
  { search_colum: "car_type", text: "車種", value: "1" }
];
