import Vue from "vue";

/* 車両通知設定用 */
Vue.prototype.$form_notify_infos_def = () => {
  return {
    email: "",
    messageId: "",
    sensor_error: {
      enable: false,
      param: ""
    },
    device_lost: {
      enable: false,
      param: ""
    },
    car_negrected: {
      enable: false,
      param: ""
    },
    obd2_error: {
      enable: false,
      param: ""
    },
    vibration: {
      enable: false,
      param: ""
    },
    stop_time_over: {
      enable: false,
      param: ""
    },
    warn_area: {
      enable: false,
      param: ""
    },
    connect_error: {
      enable: false,
      param: ""
    },
    car_negrected: {
      enable: false,
      param: ""
    },
  };
};
Vue.prototype.$form_notify_err_infos_def = () => {
  return {
    common: "",
    sensor_error: "",
    device_lost: "",
    car_negrected: "",
    obd2_error: "",
    vibration: "",
    stop_time_over: "",
    warn_area: "",
    connect_error: ""
  };
};

Vue.prototype.$account_search_items = [
  { search_colum: "account_id", text: "アカウントID", value: "0" },
  { search_colum: "name", text: "名前", value: "1" },
  { search_colum: "email", text: "メールアドレス", value: "2" },
  { search_colum: "role", text: "権限", value: "3" }
];
