<template>
  <div class="__nuxt-error-page">
    <div class="error">
      <div class="error-message" v-html="message" />
      <NuxtLink class="error-link" :to="link.to">{{ link.name }}</NuxtLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "NuxtError",
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    return {
      title: this.message,
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
        }
      ]
    };
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
    message() {
      if (this.error.statusCode === 404) {
        return "このページは存在しません。";
      }
      return this.error.message || `エラーが発生しました。`;
    },
    link() {
      switch (this.error.statusCode) {
        case 403:
          return { to: "/", name: "ログイン画面へ戻る" };
          break;
        case 404:
          return { to: "/car/car_list", name: "トップへ戻る" };
          break;
        default:
          return { to: "/", name: "ログイン画面へ戻る" };
      }
    }
  }
};
</script>

<style>
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: red;
  margin-bottom: 2%;
}

.error-message {
  width: fit-content;
  margin-bottom: 5%;
  text-align: center;
  color: red;
}
</style>