<template>
  <div class="nav-move" :class="{ 'is-active': false }">
    <div class="header-content header columns is-fullheight is-marginless" style="background-color:#3298dc;">
      <div class="navbar-item">
        <nuxt-link to="/"><img class="navi-bar-logo" src="/jalg_logo.png"/></nuxt-link>
      </div>
    </div>
    <div class="main-content main columns is-fullheight is-marginless">
      <div class="main-column container column is-paddingless is-marginless">
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  }
};
</script>

<style lang="scss" scoped>
// メモ：実際には直値指定でなくディスプレイサイズにしたい
$header-height: 60px;
div.header {
  height: $header-height;
  background: #000000;
  min-height: 40px;
}
$disp-height: 100vh;

@function main_content_height() {
  @return $disp-height;
}

div.main {
  height: main_content_height();
  height: 100%;
  min-height: 100vh;
}
.main-column {
  width: 100%;
  max-width: inherit;
}

.header-content {
  width: 100%;
  position: fixed;
  z-index: 100;
}
.main-content {
  width: 100%;
  height: 100vh;
  padding-top: 60px;
  box-sizing: border-box;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-move {
  transition: .2s all ease;
}
.nav-move.is-active {
  padding-left: 200px !important;
  transition: .2s all ease;
}
.navi-bar-logo{
  height: 35px;
  max-height: 2.75rem;
}
@media screen and (max-width: 768px) {
  div.header {
    height: 40px;
  }
  .nav-move.is-active {
    padding-left: 0 !important;
  }
  .side-column {
    top: 40px !important;
  }
  .main-content {
    padding-top: 40px;
  }
}
</style>
