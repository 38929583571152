import Vue from "vue";
import moment from "moment";

/*車両系インスタンス用共通関数 */

Vue.prototype.$form_car_infos_def = () => {
  return {
    car_id: null,
    car_number: "",
    car_type: "",
    car_vin: "",
    user_id: null,
    car_device_id: null,
    car_data_id: null,
    available_data: {
      lat_min: 0,
      lat_max: 0,
      lng_min: 0,
      lng_max: 0,
      lat_min: 0,
      lat_max: 0,
      lng_min: 0,
      lng_max: 0
    },
    isDelete: false
  };
};
Vue.prototype.$form_car_err_infos_def = () => {
  return {
    common: "",
    car_id: "",
    car_number: "",
    car_type: "",
    car_vin: "",
    user_id: "",
    car_device_id: "",
    car_data_id: "",
    available_data: ""
  };
};

Vue.prototype.$car_search_items = [
  { search_colum: "c.car_id", text: "車両ID", value: "0" },
  { search_colum: "u.name", text: "名字", value: "1" },
  { search_colum: "u.merchant", text: "加盟店", value: "2" },
  { search_colum: "c.car_number", text: "車両ナンバー", value: "3" },
  { search_colum: "u.payment_log", text: "契約状態", value: "4" }
  //    { text: '走行状態', value: '5' },
];

Vue.prototype.$car_engine_state_get_param = val => {
  var ret;

  switch (val) {
    case 0:
      ret = "permit"; // 許可
      break;
    case 1:
      ret = "prohibit"; // 停止
      break;
  }

  return ret;
};
Vue.prototype.$car_engine_state_get_param_invert = val => {
  var ret;

  switch (val) {
    case 0:
      ret = "prohibit"; // 停止
      break;
    case 1:
      ret = "permit"; // 許可
      break;
  }

  return ret;
};
Vue.prototype.$car_engine_state = val => {
  var ret = "状態不明";

  if (val != null) {
    switch (val) {
      case "on":
        ret = "走行中";
        break;
      case "off":
        ret = "停止中";
        break;
    }
  }
  return ret;
};
Vue.prototype.$car_reserve_cmd_index = {
  set_engine_boot_permission: 0, // エンジン始動/停止コマンド
  req_client_update: 1, // GPS端末アップデートコマンド
  req_car_setting_update: 2 // 車両設定アップデートコマンド
};
Vue.prototype.$car_reserve_cmd_text = {
  set_engine_boot_permission: "set_engine_boot_permission", // エンジン始動/停止コマンド
  req_client_update: "req_client_update", // GPS端末アップデートコマンド
  req_car_setting_update: "req_car_setting_update" // 車両設定アップデートコマンド
};
let car_reserve_cmd_state = {
  wait: 0, // 待機中（デフォルト）
  execWait: 1, // 実行待ち
  resultWait: 2, // 結果待ち
  resultEnd: 3 // 結果受け取り完了
}
Vue.prototype.$car_reserve_cmd_state = car_reserve_cmd_state;

Vue.prototype.$car_error_type = {
  engine: "engine", // エンジン
  gps: "gps", // GPS
  obd2: "obd2", // OBD2
  sensor: "sensor" // センサー
};

Vue.prototype.$car_error_status = {
  // 正常系
  OK: "0",
  IN_PREPARATION: "1", // 準備中（起動中等）

  ERR_TOP: "1000", // エラー判定用（エラーの場合はこれ以上の値をとる）
  // エラー系（共通）
  ERR_UNKNOWN: "1000", // 不明なエラー
  ERR_BOOT: "1001", // 起動時エラー
  ERR_DATA_ACQUISITION_FAILURE: "1002", // データ取得失敗
  ERR_ENGINE_REMOVED: "1100" // GPS端末取り外し
};

Vue.prototype.$car_engine_cmd_states = [
  { state: "on", param: "permit", text: "エンジン始動許可", value: 0 },
  { state: "off", param: "prohibit", text: "エンジン始動禁止", value: 1 }
];

Vue.prototype.$car_engine_cmd_state_value = val => {
  /*
        return car_engine_cmd_states.find((item) => {
            return item.state == val;
        }).value;
    */
};

Vue.prototype.$car_engine_cmd_param_value = val => {
  if (val === "permit") {
    return 0;
  } else {
    return 1;
  }
  /*
        return car_engine_cmd_states.find((item) => {
            return item.param == val;
        }).value;
    */
};

Vue.prototype.$time_format = val => {
  var ret = moment(val);

  return ret.format("YYYY/MM/DD HH:mm");
};

Vue.prototype.$car_device_state = val => {
  let ret = "状態不明";

  if (val != null) {
    if (val == 0) {
      ret = "正常";
    } else {
      ret = "エラー発生";
    }
  }
  return ret;
};

Vue.prototype.$car_connection_state = val => {
  let ret = "状態不明";

  if (val != null) {
    if (val === "connected") {
      ret = "接続中";
    } else {
      ret = "切断中";
    }
  }
  return ret;
};

Vue.prototype.$car_engine_line_state = val => {
  let ret = "許可中";

  if (val != null) {
    if (val === "connected") {
      ret = "許可中";
    } else {
      ret = "禁止中";
    }
  }
  return ret;
};

Vue.prototype.$car_engine_line_is_connect = val => {
  if (val == null) return false;

  if (val === "connected") {
    return true;
  } else {
    return false;
  }
};
// ログ系データ存在チェック
Vue.prototype.$car_log_data_exist = val => {
  if (val == null || val.length == 0) {
    return false;
  } else {
    return true;
  }
};

// 車両の位置が許可エリア外か判定
Vue.prototype.$car_available_area_out_judge = (lat, lng, areas) => {
  if( !lat || !lng || !areas || areas.length === 0 ){
    return false;
  }

  let result = false;
  const lat_val = parseFloat(lat);
  const lng_val = parseFloat(lng);
  // 中心からの距離を比較する
  areas.forEach(area => {
    const check_radius = Math.sqrt((Math.pow(lat_val - parseFloat(area.lat_min), 2)) + (Math.pow(lng_val - parseFloat(area.lng_min), 2)));
    const radius = Math.sqrt((Math.pow(parseFloat(area.lat_max) - parseFloat(area.lat_min), 2)) + (Math.pow(parseFloat(area.lng_max) - parseFloat(area.lng_min), 2)));
    if ( radius <= check_radius) {
      result = true;
    }
  })
  return result;
};

// 車両の位置が禁止エリア内か判定
Vue.prototype.$car_disable_area_in_judge = (lat, lng, areas) => {
  if( !lat || !lng || !areas || areas.length === 0 ){
    return false;
  }

  let result = false;
  const lat_val = parseFloat(lat);
  const lng_val = parseFloat(lng);

  areas.forEach(area => {
    // 中心からの距離を比較する
    const check_radius = Math.sqrt((Math.pow(lat_val - parseFloat(area.lat_min), 2)) + (Math.pow(lng_val - parseFloat(area.lng_min), 2)));
    const radius = Math.sqrt((Math.pow(parseFloat(area.lat_max) - parseFloat(area.lat_min), 2)) + (Math.pow(parseFloat(area.lng_max) - parseFloat(area.lng_min), 2)));
    if (radius >= check_radius) {
      result = true;
    }
  });
  return  result;
};

// エンジン予約状態のメッセージ
Vue.prototype.$reserve_cmd_state_message = (execState) => {
  var msg = "未定義の状態";

  switch (execState) {
    case car_reserve_cmd_state.wait:
      msg = "予約コマンドなし";
      break;
    case car_reserve_cmd_state.execWait:
      msg = "予約コマンド実行待機中";
      break;
    case car_reserve_cmd_state.resultWait:
      msg = "予約コマンド結果待機中";
      break;
    case car_reserve_cmd_state.resultEnd:
      msg = "予約コマンド実行完了";
      break;
  }

  return msg;
}

// エンジン操作ログ（最新の状態）
Vue.prototype.$reserve_cmd_log = (param, execState) => {
  let msg = "未定義の状態";
  let typemsg = (param == "permit") ? "始動" : "停止";

  switch (execState) {
    case car_reserve_cmd_state.wait:
      msg = "始動／停止予約なし";
      break;
    case car_reserve_cmd_state.execWait:
      msg = typemsg + "予約中";
      break;
    case car_reserve_cmd_state.resultWait:
      msg = typemsg + "予約実行結果待ち";
      break;
    case car_reserve_cmd_state.resultEnd:
      msg = typemsg + "予約実行完了";
      break;
  }

  return msg;
}
