export default async ({ route, redirect, store }) => {
  /*
  // nuxt-link push以外のページ遷移は不正操作としてエラーを表示
  if (!process.client) {
    // throw new Error(
    //   "不正操作が行われました。トップページかログインページからやりなおしてください。"
    // );
    
    // 警告ページを表示
    console.log("hoge")
    // redirect("/warning");
  }
  */
  try {
    // ユーザー名、トークンがあるか？
    if (!store.state.authUser || !store.state.token) {
      throw new Error(
        "有効なセッション情報が見つかりません。ログインしなおしてください。"
      );
    } else {
      // トークンを検証
      let isTokenVaild = await store.dispatch("checkToken", {
        account_id: store.state.authUser,
        token: store.state.token
      });
      if (!isTokenVaild) {
        throw new Error(
          `<p>ログイン情報が失効しました。お手数ですが、再度ログインください。</p>
          ログインしなおしても改善されない場合は、管理者に問い合わせてください。`
        );
      }
    }
  } catch (err) {
    console.error(err)
    // ログインページに飛ばす
    redirect("/warning");
  }
};
