import Vue from "vue";
import moment from "moment";

/* vueインスタンス用共通関数 */

// 性別 数値⇒文字列
Vue.prototype.$gender_name = value => {
  var name;

  switch (value) {
    case 0:
      name = "男性";
      break;
    case 1:
      name = "女性";
      break;
    default:
      name = "その他";
      break;
  }

  return name;
};

// 権限 数値⇒文字列
Vue.prototype.$role_name = value => {
  var name;

  switch (value) {
    case 0:
      name = "Administrator";
      break;
    case 1:
      name = "Manager";
      break;
    case 2:
      name = "Common";
      break;
    default:
      name = "Undefined";
      break;
  }

  return name;
};

Vue.prototype.$genderes = [
  { text: "男性", value: "0" },
  { text: "女性", value: "1" }
  //    { text: 'その他', value: '2' },
];

Vue.prototype.$roles = [
  { text: "Administrator", value: "0" },
  { text: "Manager", value: "1" },
  { text: "Common", value: "2" }
];

Vue.prototype.$delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

Vue.prototype.$checkDate = (dstart, dend, date) => {
  if (
    (dstart.length == 0 && dend.length == 0) ||
    (dstart <= date && dend >= date)
  ) {
    return true;
  } else {
    return false;
  }
};
Vue.prototype.$sorts_string = (sort_item, sort_dir) => {
  return sort_item + " " + (sort_dir === "▼" ? "desc" : "asc");
};
Vue.prototype.$birthday_string = (birthday) => {
  return moment(birthday).format("YYYY年MM月DD日");
};
Vue.prototype.$birthday_value = (birthday) => {
  return moment(birthday).format("YYYY-MM-DD");
};
