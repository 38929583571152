<template>
  <nav class="navbar header column is-10 is-info" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <!-- <div class="navbar-item"></div> -->
      <!--<i class="fa fa-bars"></i>-->
      <div
        class="navbar-burger"
        data-target="navMenu"
        @click="$store.commit('toggleMenu')"
        :class="{'is-active': $store.state.isMenuActive}"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      
    </div>
    <div class="navbar-menu" id="navMenu" :class="{'is-active': $store.state.isMenuActive}">
      <div class="navbar-item">
      <!-- nuxt-linkで@clickを使用する時は@click.nativeにする必要有り-->
      <!--
        <nuxt-link to="/" @click.native="logout_proc" class="navbar-item">ログアウト</nuxt-link>
        <nuxt-link to="/account_list" class="navbar-item">アカウント一覧</nuxt-link>
        <nuxt-link to="/account_add" class="navbar-item">アカウント追加</nuxt-link>
      -->
        <nuxt-link to="/car/car_list"><img class="navi-bar-logo" src="/jalg_logo.png"/></nuxt-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data: () => {
    return { isMenuActive: true };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    toggle_class() {
      this.isActive = !this.isActive
    },
    async logout_proc() {
      // ログアウトしてからトップページに遷移
      await this.$store.dispatch("logout");
      // メモ：下のpushで遷移させないとおかしなことになる
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
nav.header {
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
//  padding: 20px !important;
}
.navbar-burger {
  display: block !important;
  width: 20px;
  height: 18px;
}
.navbar-burger span {
  height: 2px;
  left: 0;
  width: 20px;
}
.navbar-burger span:nth-child(1) {
  top: calc(50% - 8px);
}
.navbar-burger span:nth-child(3) {
  top: calc(50% + 6px);
}
.navbar-burger.is-active span:nth-child(1) {
  -webkit-transform: translateY(5px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}
.navbar-burger.is-active span:nth-child(3) {
  -webkit-transform: translateY(-5px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}
.navi-bar-logo{
  height: 35px;
  max-height: 2.75rem;
}
</style>