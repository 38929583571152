<template>
  <div class="nav-move" :class="{ 'is-active': $store.state.isMenuActive }">
    <div class="header-content header columns is-fullheight is-marginless">
      <navbar />
    </div>
    <div class="main-content main columns is-fullheight is-marginless">
      <sidebar />
      <div class="main-column container column is-paddingless is-marginless">
        <Loading v-show="loading"></Loading>
        <nuxt />
      </div>
    </div>
    <div class="footer">
      Copyright © 2020- JALG inc. All Right Reserved. 
    </div>
  </div>
</template>

<script>
import Navbar from "~/components/Navbar.vue";
import Sidebar from "~/components/Sidebar.vue";
import Loading from "~/components/loading.vue";

export default {
  middleware: "auth",
  components: {
    Navbar,
    Sidebar,
    Loading
  },
  data() {
    return {
      loading: false
    };
  },
  created() {
    this.setListener();
    if (process.client) {
      // 作成時にイベントリスナーに登録
      window.addEventListener("beforeunload", this.unload);
    }
  },
  mounted() {
  },
  beforeDestroy() {
    // 破棄される前にイベントリスナーから削除
    window.removeEventListener("beforeunload", this.unload);
  },
  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave", to, from, next)
    // next()は必ず呼び出さないといけない
    if (to.fullPath != from.fullPath) {
      next();
    }
    else {
      this.$router.reload();
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate", to, from, next)
    // next()は必ず呼び出さないといけない
    if (to.fullPath != from.fullPath) {
      next();
    }
    else {
      this.$router.reload();
    }
  },
  methods: {
    setListener() {
      // ページ内のemitで発火させるイベント名を登録
      this.$nuxt.$on("loading", this.loadingProc);
      this.$nuxt.$on("loadingCancel", this.loadingCancel);
    },
    // 指定ミリ秒ローディング画面を表示
    loadingProc(msec) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, msec);
    },
    // ローディングキャンセル
    loadingCancel() {
      this.loading = false;
    },
    unload(event) {
      event.preventDefault();
      // console.log(event)
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
// メモ：実際には直値指定でなくディスプレイサイズにしたい
$header-height: 60px;
div.header {
  height: $header-height;
  background: #000000;
  min-height: 40px;
}
$disp-height: 100vh;

@function main_content_height() {
  @return $disp-height;
}

div.main {
  height: main_content_height();
  height: 100%;
  min-height: 100vh;
}
.main-column {
  width: 100%;
  max-width: inherit;
}

.header-content {
  width: 100%;
  position: fixed;
  z-index: 100;
}
.main-content {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}
.nav-move {
  transition: .2s all ease;
}
.nav-move.is-active {
  padding-left: 200px !important;
  transition: .2s all ease;
}
@media screen and (max-width: 768px) {
  div.header {
    height: 40px;
  }
  .nav-move.is-active {
    padding-left: 0 !important;
  }
  .side-column {
    top: 40px !important;
  }
  .main-content {
    padding-top: 40px;
  }
}
</style>
