import axios from "axios";
import Vuex from "vuex";

export const state = () => ({
  authUser: null,
  name: null,
  role: null,
  token: null,
  isMenuActive: true,
  account_id: null,
  car_id: null,
  user_id: null,
  car_data_id: null,
  car_device_id: null,
  car_search_query: {
    search_item: "0",
    search_text: "",
    currentPage: 0,
    size: 20,
    pageRange: 20,
    list_cnt: 0,
    dispCnt: 20,
    sort_item: "car_id",
    sort_dir: "▲",
    isShowDelete: false,
    isNormal: false
  }
});

export const mutations = {
  SET_USER(state, user) {
    state.authUser = user;
  },
  SET_NAME(state, name) {
    state.name = name;
  },
  SET_ROLE(state, role) {
    state.role = role;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_ACT_ID(state, account_id) {
    state.account_id = account_id;
  },
  SET_CAR_ID(state, car_id) {
    state.car_id = car_id;
  },
  SET_USER_ID(state, user_id) {
    state.user_id = user_id;
  },
  SET_DATA_ID(state, car_data_id) {
    state.car_data_id = car_data_id;
  },
  SET_DEVICE_ID(state, car_device_id) {
    state.car_device_id = car_device_id;
  },
  SET_CAR_SEARCH_QUERY(state, query) {
    state.car_search_query = query;
  },
  toggleMenu(state) {
    state.isMenuActive = !state.isMenuActive;
  },
  resetMenu(state) {
    // state.isMenuActive = false;
  }
};

export const actions = {
  // nuxtServerInit is called by Nuxt.js before server-rendering every page
  nuxtServerInit({ commit }, { req }) {
    if (req.session) {
      if (req.session.authUser) {
        commit("SET_USER", req.session.authUser);
        commit("SET_ROLE", req.session.role);
        commit("SET_TOKEN", req.session.token);
      }
      if (req.session.account_id) {
        commit("SET_ACT_ID", req.session.account_id);
      }
      if (req.session.car_id) {
        commit("SET_CAR_ID", req.session.car_id);
      }
      if (req.session.user_id) {
        commit("SET_USER_ID", req.session.user_id);
      }
      if (req.session.car_data_id) {
        commit("SET_DATA_ID", req.session.car_data_id);
      }
      if (req.session.car_device_id) {
        commit("SET_DEVICE_ID", req.session.car_device_id);
      }
    }
  },
  async login({ commit }, { username, password }) {
    try {
      const res = await axios.post("/api/login", { username, password });
      commit("SET_USER", res.data.id);
      commit("SET_NAME", res.data.username);
      commit("SET_ROLE", res.data.role);
      commit("SET_TOKEN", res.data.token);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error("ログインに失敗しました。ログイン情報を確認してください。");
      }
      throw error;
    }
  },

  async logout({ commit }) {
    await axios.post("/api/logout");
    commit("SET_USER", null);
    commit("SET_ROLE", null);
    commit("SET_TOKEN", null);
  },
  async checkToken({ commit }, { account_id, token }) {
    var res;
    try {
      const baseUrl = process.browser ? '' : this.app.context.env.apiUrl;
      res = await axios.post(baseUrl + "/api/checkToken", {
        account_id: account_id,
        token: token
      });
      //      }
    } catch (err) {
      throw new Error(
        "トークン検証に失敗しました。管理者に問い合わせてください。詳細：" + err
      );
      return false;
    }

    return res.data.ok;
  },
  async account_list_count({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/account_list_count", {
        search_item: info.search_item,
        search_text: info.search_text,
        isDelete: info.isDelete
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "アカウント一覧数が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data.count;
  },
  async account_list({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/account_list", {
        offset: info.offset,
        limit: info.limit,
        search_item: info.search_item,
        search_text: info.search_text,
        sorts: info.sorts,
        isDelete: info.isDelete
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "アカウント一覧が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },
  async account_get_detail({ commit }, { account_id }) {
    var res = [];
    try {
      res = await axios.post("/api/account_get_detail", {
        account_id: account_id
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "アカウント詳細が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },

  async account_get_newid({ commit }) {
    const crypto = require("crypto");
    const N = 8 + Math.random() * 8; // 8～16文字のランダムな文字列とする
    var act_id;
    try {
      var retryCnt = 0;
      const max_retry = 10;

      while (retryCnt < max_retry) {
        // ランダムなアカウントIDを生成
        act_id = crypto
          .randomBytes(N)
          .toString("base64")
          .substring(0, N);
        // 生成したIDが登録済みかチェック
        var res = await axios.post("/api/account_exist_check", {
          check_item: "account_id",
          check_value: act_id
        });
        if (res.data.isExist == false) {
          break;
        }
        retryCnt = retryCnt + 1;
      }
      if (retryCnt == max_retry) {
        throw new Error(
          "アカウントID生成に失敗しました。管理者に問い合わせてください 詳細：" +
            error
        );
      }
    } catch (error) {
      throw new Error(
        "アカウントID生成に失敗しました。管理者に問い合わせてください 詳細：" +
          error
      );
    }

    return act_id;
  },

  async account_add({ commit }, { infos }) {
    try {
      await axios.post("/api/account_add", { info: infos });
    } catch (error) {
      throw new Error("登録失敗しました。管理者に問い合わせてください");
    }
  },
  async account_update({ commit }, { infos }) {
    try {
      await axios.post("/api/account_update", { info: infos });
    } catch (error) {
      throw new Error("更新失敗しました。管理者に問い合わせてください");
    }
  },
  async account_notify({ commit }, { account_id }) {
    var res = [];
    try {
      res = await axios.post("/api/account_notify", { account_id: account_id });
    } catch (error) {
      throw new Error(
        "通知設定の取得に失敗しました。管理者に問い合わせてください 詳細：" +
          error
      );
    }

    return res.data.notify;
  },
  async account_notify_update({ commit }, { account_id, infos }) {
    try {
      await axios.post("/api/account_notify_update", {
        account_id: account_id,
        info: infos
      });
    } catch (error) {
      console.log(error)
      throw new Error(
        "通知設定の更新に失敗しました。管理者に問い合わせてください"
      );
    }
  },
  async password_reset({ commit }, { infos }) {
    try {
      await axios.post("/api/password_reset", { info: infos });
    } catch (error) {
      throw new Error(
        "パスワード再設定に失敗しました。管理者に問い合わせてください"
      );
    }
  },
  async account_delete({ commit }, { account_id, isDelete }) {
    try {
      await axios.post("/api/account_delete", {
        account_id: account_id,
        isDelete: isDelete
      });
    } catch (error) {
      throw new Error(
        "削除失敗しました。管理者に問い合わせてください 詳細：" + error.message
      );
    }
  },
  async email_exist({ commit }, { email }) {
    var res = [];
    try {
      var res = await axios.post("/api/account_exist_check", {
        check_item: "email",
        check_value: email
      });
    } catch (error) {
      throw new Error("e-mail照合に失敗しました。管理者に問い合わせてください");
    }
    return res.data.isExist;
  },
  async resetpassword_email({ commit }, { email }) {
    try {
      await axios.post("/api/resetpassword_email", { email: email });
    } catch (error) {
      throw new Error(
        "メール送信失敗しました。管理者に問い合わせてください 詳細：" +
          error.message
      );
    }
  },
  async set_act_id({ commit }, { account_id }) {
    try {
      await axios.post("/api/session_account_id", { account_id: account_id });
      commit("SET_ACT_ID", account_id);
    } catch (err) {
      throw new Error(
        "アカウントID設定に失敗しました。管理者に問い合わせてください"
      );
    }
  },
  async car_get_newid({ commit }) {
    /* 以前の処理
    const crypto = require("crypto");
    const N = 8 + Math.random() * 8; // 8～16文字のランダムな文字列とする
    var user_id;
    try {
      var retryCnt = 0;
      const max_retry = 10;

      while (retryCnt < max_retry) {
        // ランダムなユーザーIDを生成
        user_id = crypto
          .randomBytes(N)
          .toString("base64")
          .substring(0, N);
        // 生成したIDが登録済みかチェック
        var res = await axios.post("/api/car_exist_check", {
          check_item: "user_id",
          check_value: user_id
        });
        if (res.data.isExist == false) {
          break;
        }
        retryCnt = retryCnt + 1;
      }
      if (retryCnt == max_retry) {
        throw new Error(
          "顧客ID生成に失敗しました。管理者に問い合わせてください 詳細：" +
            error
        );
      }
    } catch (error) {
      throw new Error(
        "顧客ID生成に失敗しました。管理者に問い合わせてください 詳細：" +
          error
      );
    }
    */
    // 2020/6/25 オートインクリメントの主キーに変更したのでレコード数＋１を返すようにする
    let user_id = 0;

    try{
      let res = await axios.post("/api/car_get_newid");
      user_id = res.data.newid;
    }catch(error){
      throw new Error(
        "顧客ID生成に失敗しました。管理者に問い合わせてください 詳細：" +
          error
      );
    }
    return user_id;
  },
  async car_add({ commit }, { infos }) {
    try {
      await axios.post("/api/car_add", { info: infos });
    } catch (error) {
      throw new Error(
        "登録失敗しました。管理者に問い合わせてください 詳細:",
        error
      );
    }
  },
  async car_list_count({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/car_list_count", {
        search_item: info.search_item,
        search_text: info.search_text,
        isDelete: info.isDelete,
        isNormal: info.isNormal
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "車両一覧数が取得できません。管理者に問い合わせてください 詳細：" +
            error
        );
      }
      throw error;
    }
    return res.data.count;
  },
  async car_list({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/car_list", {
        offset: info.offset,
        limit: info.limit,
        search_item: info.search_item,
        search_text: info.search_text,
        sorts: info.sorts,
        isDelete: info.isDelete,
        isNormal: info.isNormal
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "車両一覧が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },
  async car_get_detail({ commit }, { car_id }) {
    var res = null;
    try {
      res = await axios.post("/api/car_get_detail", { car_id: car_id });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "車両詳細が取得できません。管理者に問い合わせてください 詳細：" +
            error
        );
      }
      throw error;
    }
    return res.data;
  },
  async car_update({ commit }, { infos }) {
    try {
      await axios.post("/api/car_update", { info: infos });
    } catch (error) {
      throw new Error("更新失敗しました。管理者に問い合わせてください");
    }
  },
  async car_status_update({ commit }, { car_id, car_status }) {
    try {
      await axios.post("/api/car_status_update", {
        car_id: car_id,
        car_status: car_status
      });
    } catch (error) {
      throw new Error(
        "更新に失敗しました。管理者に問い合わせてください 詳細：" + error.message
      );
    }
  },
  async car_delete({ commit }, { car_id, isDelete }) {
    try {
      await axios.post("/api/car_delete", {
        car_id: car_id,
        isDelete: isDelete
      });
    } catch (error) {
      throw new Error(
        "削除失敗しました。管理者に問い合わせてください 詳細：" + error.message
      );
    }
  },
  async set_car_id({ commit }, { car_id }) {
    try {
      await axios.post("/api/session_car_id", { car_id: car_id });
      commit("SET_CAR_ID", car_id);
    } catch (err) {
      throw new Error(
        "車両ID設定に失敗しました。管理者に問い合わせてください 詳細:" + err
      );
    }
  },
  async car_user_get_newid({ commit }) {
    const crypto = require("crypto");
    const N = 8 + Math.random() * 8; // 8～16文字のランダムな文字列とする
    var user_id;
    try {
      var retryCnt = 0;
      const max_retry = 10;

      while (retryCnt < max_retry) {
        // ランダムなユーザーIDを生成
        user_id = crypto
          .randomBytes(N)
          .toString("base64")
          .substring(0, N);
        // 生成したIDが登録済みかチェック
        var res = await axios.post("/api/user_exist_check", {
          check_item: "user_id",
          check_value: user_id
        });
        if (res.data.isExist == false) {
          break;
        }
        retryCnt = retryCnt + 1;
      }
      if (retryCnt == max_retry) {
        throw new Error(
          "顧客ID生成に失敗しました。管理者に問い合わせてください 詳細：" +
            error
        );
      }
    } catch (error) {
      throw new Error(
        "顧客ID生成に失敗しました。管理者に問い合わせてください 詳細：" +
          error
      );
    }

    return user_id;
  },
  async car_user_add({ commit }, { infos }) {
    try {
      await axios.post("/api/user_add", { info: infos });
    } catch (error) {
      throw new Error("登録失敗しました。管理者に問い合わせてください");
    }
  },
  async car_user_list_count({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/user_list_count", {
        search_item: info.search_item,
        search_text: info.search_text,
        isDelete: info.isDelete,
        isRegistered: info.isRegistered,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "顧客一覧数が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data.count;
  },
  async car_user_list({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/user_list", {
        offset: info.offset,
        limit: info.limit,
        search_item: info.search_item,
        search_text: info.search_text,
        sorts: info.sorts,
        isDelete: info.isDelete,
        isRegistered: info.isRegistered,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "顧客一覧が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },
  async car_user_get_detail({ commit }, { user_id }) {
    var res = null;
    try {
      res = await axios.post("/api/user_get_detail", { user_id: user_id });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "顧客詳細が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },
  async car_user_update({ commit }, { infos }) {
    try {
      await axios.post("/api/user_update", { info: infos });
    } catch (error) {
      throw new Error("更新失敗しました。管理者に問い合わせてください");
    }
  },
  async car_user_delete({ commit }, { user_id, isDelete }) {
    try {
      await axios.post("/api/user_delete", {
        user_id: user_id,
        isDelete: isDelete
      });
    } catch (error) {
      throw new Error(
        "削除失敗しました。管理者に問い合わせてください 詳細：" + error.message
      );
    }
  },
  async set_data_id({ commit }, { car_data_id }) {
    try {
      await axios.post("/api/session_data_id", { car_data_id: car_data_id });
      commit("SET_DATA_ID", car_data_id);
    } catch (err) {
      throw new Error(
        "車両データID設定に失敗しました。管理者に問い合わせてください 詳細:" +
          err
      );
    }
  },
  async set_user_id({ commit }, { user_id }) {
    try {
      await axios.post("/api/session_user_id", { user_id: user_id });
      commit("SET_USER_ID", user_id);
    } catch (err) {
      throw new Error(
        "顧客ID設定に失敗しました。管理者に問い合わせてください"
      );
    }
  },
  async car_get_connection_log({ commit }, { car_data_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_connection_log", {
        car_data_id: car_data_id
      });
    } catch (error) {
      throw new Error(
        "GPS端末GPSデータ取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },  
  async car_get_engine_log({ commit }, { car_data_id, from, to, limit }) {
    var res;

    try {
      res = await axios.post("/api/car_get_engine_log", {
        car_data_id: car_data_id,
        from: from,
        to: to,
        limit: limit
      });
    } catch (error) {
      throw new Error(
        "車両エンジンログ取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_reserve_cmd({ commit }, { car_data_id, cmd_idx }) {
    var res;

    try {
      res = await axios.post("/api/car_get_reserve_cmd", {
        car_data_id: car_data_id,
        cmd_idx: cmd_idx
      });
    } catch (error) {
      throw new Error(
        "GPS端末コマンド情報取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_delete_reserve_engine_cmd({ commit }, { car_data_id, cmd_idx }) {
    try {
      await axios.post("/api/car_delete_reserve_engine_cmd", {
        car_data_id: car_data_id
      });
    } catch (error) {
      throw new Error(
        "GPS端末エンジンコマンド削除に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
  },
  async car_engine_state_reserve({ commit }, { car_data_id, cmd_info }) {
    try {
      await axios.post("/api/car_engine_state_reserve", {
        car_data_id: car_data_id,
        cmd_info: cmd_info
      });
    } catch (error) {
      throw new Error(
        "車両エンジン始動/停止予約に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
  },
  async get_reserve_requests({ commit }, { car_id, status }) {
    var res = [];
    try {
      res = await axios.post("/api/get_reserve_requests", {
        car_id: car_id
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "車両エンジン始動/停止申請を取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },
  async car_engine_state_reserve_request({ commit }, { request }) {
    try {
      console.log(request)
      await axios.post("/api/car_engine_state_reserve_request", {
        request: request
      });
    } catch (error) {
      throw new Error(
        "車両エンジン始動/停止申請に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
  },
  async car_delete_reserve_engine_cmd_request({ commit }, { request_id }) {
    try {
      await axios.post("/api/car_delete_reserve_engine_cmd_request", {
        request_id: request_id
      });
    } catch (error) {
      throw new Error(
        "車両エンジン始動/停止申請の削除に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
  },
  async car_get_error_log({ commit }, { car_data_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_error_log", {
        car_data_id: car_data_id
      });
    } catch (error) {
      throw new Error(
        "GPS端末エラー取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_gps_log({ commit }, { car_data_id, from, to }) {
    var res;

    try {
      res = await axios.post("/api/car_get_gps_log", {
        car_data_id: car_data_id,
        from: from,
        to: to
      });
    } catch (error) {
      throw new Error(
        "GPS端末GPSデータ取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_battery_log({ commit }, { car_data_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_battery_log", {
        car_data_id: car_data_id
      });
    } catch (error) {
      throw new Error(
        "GPS端末エラー取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_sensor_log({ commit }, { car_data_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_sensor_log", {
        car_data_id: car_data_id
      });
    } catch (error) {
      throw new Error(
        "GPS端末センサーデータ取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_obd2_log({ commit }, { car_data_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_obd2_log", {
        car_data_id: car_data_id
      });
    } catch (error) {
      throw new Error(
        "GPS端末OBD2データ取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_available_data({ commit }, { car_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_available_data", { car_id: car_id });
    } catch (error) {
      throw new Error(
        "車両警告エリアの取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_available_update({ commit }, { car_id, available_data }) {
    var res;

    try {
      res = await axios.post("/api/car_available_update", {
        car_id: car_id,
        available_data: available_data
      });
    } catch (error) {
      throw new Error(
        "車両警告エリアの設定に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_available_areas({ commit }, { car_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_available_areas", { car_id: car_id });
    } catch (error) {
      throw new Error(
        "車両警告エリアの取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_available_areas_update({ commit }, { car_id, available_areas }) {
    var res;

    try {
      res = await axios.post("/api/car_available_areas_update", {
        car_id: car_id,
        available_areas: available_areas
      });
    } catch (error) {
      throw new Error(
        "車両警告エリアの設定に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_get_disable_areas({ commit }, { car_id }) {
    var res;

    try {
      res = await axios.post("/api/car_get_disable_areas", { car_id: car_id });
    } catch (error) {
      throw new Error(
        "車両警告エリアの取得に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_disable_areas_update({ commit }, { car_id, disable_areas }) {
    var res;

    try {
      res = await axios.post("/api/car_disable_areas_update", {
        car_id: car_id,
        disable_areas: disable_areas
      });
    } catch (error) {
      throw new Error(
        "車両警告エリアの設定に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async car_device_get_newid({ commit }) {
    const crypto = require("crypto");
    const N = 8 + Math.random() * 8; // 8～16文字のランダムな文字列とする
    var car_device_id;
    try {
      var retryCnt = 0;
      const max_retry = 10;

      while (retryCnt < max_retry) {
        // ランダムなGPS端末IDを生成
        car_device_id = crypto
          .randomBytes(N)
          .toString("base64")
          .substring(0, N);
        // 生成したIDが登録済みかチェック
        var res = await axios.post("/api/device_exist_check", {
          check_item: "car_device_id",
          check_value: car_device_id
        });
        if (res.data.isExist == false) {
          break;
        }
        retryCnt = retryCnt + 1;
      }
      if (retryCnt == max_retry) {
        throw new Error(
          "GPS端末ID生成に失敗しました。管理者に問い合わせてください 詳細：" +
            error
        );
      }
    } catch (error) {
      throw new Error(
        "GPS端末ID生成に失敗しました。管理者に問い合わせてください 詳細：" +
          error
      );
    }

    return car_device_id;
  },
  async car_device_add({ commit }, { infos }) {
    var res = [];
    try {
      res = await axios.post("/api/device_add", { info: infos });
    } catch (error) {
      let response = error.response;
      let message = response.data.message;
      if( message == "already registered" ){
        throw new Error("入力されたIMEIは既に使用されています。");
      }
      else {
        throw new Error("登録失敗しました。管理者に問い合わせてください");
      }
    }
    return res.data;
  },
  async car_device_list_count({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/device_list_count", {
        search_item: info.search_item,
        search_text: info.search_text,
        isDelete: info.isDelete,
        isRegistered: info.isRegistered,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "GPS端末一覧数が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data.count;
  },

  async car_device_list({ commit }, { info }) {
    var res = [];
    try {
      res = await axios.post("/api/device_list", {
        offset: info.offset,
        limit: info.limit,
        search_item: info.search_item,
        search_text: info.search_text,
        sorts: info.sorts,
        isDelete: info.isDelete,
        isRegistered: info.isRegistered,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "GPS端末一覧が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },
  async car_device_get_detail({ commit }, { car_device_id }) {
    var res = null;
    try {
      res = await axios.post("/api/device_get_detail", {
        car_device_id: car_device_id
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "GPS端末詳細が取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },

  async car_device_update({ commit }, { infos }) {
    try {
      await axios.post("/api/device_update", { info: infos });
    } catch (error) {
      throw new Error("更新失敗しました。管理者に問い合わせてください");
    }
  },
  async car_device_delete(
    { commit },
    { car_device_id, car_data_id, isDelete }
  ) {
    try {
      await axios.post("/api/device_delete", {
        car_device_id: car_device_id,
        car_data_id: car_data_id,
        isDelete: isDelete
      });
    } catch (error) {
      throw new Error(
        "削除失敗しました。管理者に問い合わせてください 詳細：" + error.message
      );
    }
  },
  async set_device_id({ commit }, { car_device_id }) {
    try {
      await axios.post("/api/session_device_id", {
        car_device_id: car_device_id
      });
      commit("SET_DEVICE_ID", car_device_id);
    } catch (err) {
      throw new Error(
        "GPS端末ID設定に失敗しました。管理者に問い合わせてください"
      );
    }
  },
  async set_notify_items({ commit }, { items }) {
    commit("SET_NOTIFY_ITEMS", items);
  },
  async get_area_templates({ commit }, { type }) {
    var res = [];
    try {
      res = await axios.post("/api/get_area_templates", {
        type: type,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error(
          "テンプレートを取得できません。管理者に問い合わせてください"
        );
      }
      throw error;
    }
    return res.data;
  },
  async area_template_update({ commit }, { template }) {
    var res;

    try {
      res = await axios.post("/api/area_template_update", {
        template: template
      });
    } catch (error) {
      throw new Error(
        "テンプレートの保存に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  async area_template_delete({ commit }, { template_id }) {
    var res;

    try {
      res = await axios.post("/api/area_template_delete", {
        template_id: template_id
      });
    } catch (error) {
      throw new Error(
        "テンプレートの削除に失敗しました。管理者に問い合わせてください。詳細：",
        error
      );
    }
    return res.data;
  },
  set_car_search_query({ commit }, { query }) {
    commit("SET_CAR_SEARCH_QUERY", query);
  },
  async car_data_sync({ commit }, { car_id, car_data_id }) {
    try {
      const res = await axios.post("/api/car_data_sync", {
        car_id: car_id,
        car_data_id: car_data_id
      });
      console.log(res.data);
    } catch (err) {
      throw new Error(
        "過去データ同期に失敗しました。管理者に問い合わせてください"
      );
    }
  },
};
