<template>
  <!--
  オリジナル
  <aside class="column is-2 is-narrow-mobile is-fullheight section is-hidden-mobile has-background-dark">
  -->
  <aside :class="{'is-active': $store.state.isMenuActive}" class="side column side-column is-fullheight has-text-white has-background-dark is-paddingless">
    <div class="is-size-1 has-text-centered has-text-white">
      <span class="icon">
        <i class="fa fa-user-circle"></i>
      </span>
      <div class="has-text-centered is-size-6 account-name">{{ user }}</div>
    </div>
    <ul class="menu-list is-size-7">
      <li class="menu-line line_tb">
        <a href="#" :class="{ 'is-active': activeNum == 1 }" @click="changeActiveLink(1)">
          <span class="icon">
            <i class="fa fa-car"></i>
          </span> 車両管理
        </a>
        <ul v-show="activeNum == 1">
          <li class="has-text-size-7">
            <nuxt-link
              to="/car/car_list"
              :class="{ 'is-active': activeChildNum == 1 }"
              @click.native="changeActiveChildLink(1)"
            >
              <span class="icon">
                <i class="fa fa-list-ul"></i>
              </span>
              車両一覧
            </nuxt-link>
          </li>
          <li class>
            <nuxt-link
              to="/car/car_add"
              :class="{ 'is-active': activeChildNum == 2 }"
              @click.native="changeActiveChildLink(2)"
            >
              <span class="icon">
                <i class="fa fa-plus-circle"></i>
              </span>
              車両登録
            </nuxt-link>
          </li>
          <li class>
            <nuxt-link
              to="/car/car_user_device_add"
              :class="{ 'is-active': activeNum == 5 }"
              @click.native="changeActiveLink(5)"
            >
              <span class="icon">
                <i class="fa fa-plus-circle"></i>
              </span>
              登録ウィザード
            </nuxt-link>            
          </li>          
        </ul>
      </li>
      <li class="menu-line line_b">
        <a href="#" :class="{ 'is-active': activeNum == 2 }" @click="changeActiveLink(2)">
          <span class="icon">
            <i class="fa fa-user"></i>
          </span>顧客管理
        </a>
        <ul v-if="activeNum == 2">
          <li class>
            <nuxt-link
              to="/car/car_user_list"
              :class="{ 'is-active': activeChildNum == 1 }"
              @click.native="changeActiveChildLink(1)"
            >
              <span class="icon">
                <i class="fa fa-list-ul"></i>
              </span>
              顧客一覧
            </nuxt-link>
          </li>
          <li class>
            <nuxt-link
              to="/car/car_user_add"
              :class="{ 'is-active': activeChildNum == 2 }"
              @click.native="changeActiveChildLink(2)"
            >
              <span class="icon">
                <i class="fa fa-user-plus"></i>
              </span>
              顧客登録
            </nuxt-link>
          </li>
        </ul>
      </li>
      <li class="menu-line line_b">
        <a href="#" :class="{ 'is-active': activeNum == 3 }" @click="changeActiveLink(3)">
          <span class="icon">
            <i class="fa fa-globe"></i>
          </span> GPS端末管理
        </a>
        <ul v-if="activeNum == 3">
          <li class="has-text-size-7">
            <nuxt-link
              to="/car/device/list"
              :class="{ 'is-active': activeChildNum == 1 }"
              @click.native="changeActiveChildLink(1)"
            >
              <span class="icon">
                <i class="fa fa-list-ul"></i>
              </span>
              GPS端末一覧
            </nuxt-link>
          </li>
          <li class>
            <nuxt-link
              to="/car/device/add"
              :class="{ 'is-active': activeChildNum == 2 }"
              @click.native="changeActiveChildLink(2)"
            >
              <span class="icon">
                <i class="fa fa-plus-circle"></i>
              </span>
              GPS端末登録
            </nuxt-link>
          </li>
        </ul>
      </li>
      <li class="menu-item line_b">
        <a href="#" :class="{ 'is-active': activeNum == 4 }" @click="changeActiveLink(4)">
          <span class="icon">
            <i class="fa fa-cog"></i>
          </span> 設定
        </a>
        <ul v-if="activeNum == 4">
          <li class>
            <nuxt-link
              v-if="isManager"
              to="/account/account_list"
              :class="{ 'is-active': activeChildNum == 1 }"
              @click.native="changeActiveChildLink(1)"
            >
              <span class="icon">
                <i class="fa fa-list-ul"></i>
              </span>
              管理者アカウント一覧
            </nuxt-link>
          </li>
          <li class>
            <nuxt-link
              v-if="isManager"
              to="/account/account_add"
              :class="{ 'is-active': activeChildNum == 2 }"
              @click.native="changeActiveChildLink(2)"
            >
              <span class="icon">
                <i class="fa fa-user-plus"></i>
              </span>
              管理者アカウント登録
            </nuxt-link>
          </li>
          <li class>
            <nuxt-link
              to="/notify"
              :class="{ 'is-active': activeChildNum == 3 }"
              @click.native="changeActiveChildLink(3)"
            >
              <span class="icon">
                <i class="fa fa-user-plus"></i>
              </span>
              通知設定
            </nuxt-link>
          </li>
        </ul>
      </li>
      <li class="menu-item line_b">
        <a href="#" @click="logout_proc()">
          <span class="icon">
            <i class="fa fa-sign-out"></i>
          </span>
          ログアウト
        </a>
      </li>
    </ul>
  </aside>
</template>


<script>
export default {
  data() {
    return {
      activeNum: 0,
      activeChildNum: 0
    };
  },
  computed: {
    user() {
      return this.$store.state.name
    },
    isManager() {
      return (this.$store.state.role === 0 || this.$store.state.role === 1)
    }
  },
  methods: {
    async logout_proc() {
      // ログアウトしてからトップページに遷移
      await this.$store.dispatch("logout");
      // メモ：下のpushで遷移させないとおかしなことになる
      this.$router.push("/");
    },
    changeActiveLink(n) {
      this.activeNum = n;
      this.activeChildNum = 0; // 子メニューはクリア
    },
    changeActiveChildLink(n) {
      this.activeChildNum = n;
    }
  }
};
</script>
<style lang="scss" scoped>
aside.side {
  padding-right: 0px;
}
ul.menu-list {
  padding-right: 128;
}
.menu-list {
    line-height: 3;
}
.line_tb {
  border-top-style: solid;
  border-bottom-style: solid;
  border-bottom-color: #777;
  border-width: thin;
  color: #ffffff;
}

.line_b {
  border-bottom-style: solid;
    border-bottom-color: #777;
  color: #ffffff;
  border-width: thin;
}
.nav-title h1 {
  border-bottom: 3px #ffffff solid;
  padding: 20px 0;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-name{
  margin-bottom: 5px;
}

.side-column {
  position: fixed;
  z-index: 1000;
  width: 200px;
  height: 100%;
  top: 0;
  left: 0;
  transform:translateX(-200px);
  transition: .2s all ease;
}
.side-column.is-active {
  transform: translateX(0);
  transition: .2s all ease;
}
@media screen and (max-width:768px){
.nav-title h1 {
  height: 40px;
}
}
/* transition */
.expand-transition {
  transition: all .2s ease;
  height: 30px;
  overflow: hidden;
}
.expand-enter, .expand-leave {
  height: 0;
  opacity: 0;
}
</style>
