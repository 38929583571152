import Vue from 'vue'
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import { required, min_value, max_value, min, max, email, confirmed } from 'vee-validate/dist/rules'

extend(
    'required', {
        ...required,
        message: "必須項目です"
    }
);
extend(
    'min_value', {
        ...min_value,
        message: "年齢が不正です"
    }
);
extend(
    'max_value', {
        ...max_value,
        message: "年齢が不正です"
    }
);
extend(
    'min', {
        ...min,
        message: "8文字以上で入力してください"
    }
);
extend(
    'max', {
        ...max,
        message: "32文字以上で入力してください"
    }
);
extend(
    'email', {
        ...email,
        message: "メールアドレスの形式が不正です"
    }
);
extend(
    'confirmed', {
        ...confirmed,
        message: "パスワードが一致しません"
    }
);

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
localize('ja', ja)