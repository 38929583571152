<template>
  <div class="container">
    <nuxt-error :error="error"></nuxt-error>
  </div>
</template>

<script>
import NuxtError from "@/components/nuxt-error";

export default {
  layout: "reset",
  props: ["error"],
  components: {
    NuxtError
  }
};
</script>